<template>
    <div id="homePart">
        <!-- 操作按钮 -->
        <div class="actionPart">
            <SnActionBtn :showNameList="['用户手册', '退出']"></SnActionBtn>
        </div>
        <!-- 左上角标题 -->
        <div class="homeTitle">
            <img src="@image/home/titleBg.png" alt="" class="titleBg" />
            <img src="@image/home/title.png" alt="" class="title" />
            <div class="title-desc">
                <p>布朗先载茗，千年唤腊音。</p>
                <p>傣家基诺用，民语祖根寻。</p>
                <p>考证扬中外，参观赞绿金。</p>
                <p>天然文物馆，万亩古茶林。</p>
            </div>
        </div>
        <!-- 中间菜单 -->
        <div class="homeContainer">
            <div
                v-for="(item, index) in systems"
                :key="index"
                class="menuItem animated bounceIn"
                @click="turnSystem(item)"
            >
                <img
                    class="icon"
                    :class="[item.state ? '' : 'noPower']"
                    :src="require(`@image/${item.bgPicture}.png`)"
                />

                <div class="name" :class="[item.state ? '' : 'noPower']">
                    <p v-for="(name, i) in item.name" :key="i">
                        {{ name }}
                    </p>
                </div>
            </div>
        </div>
        <!-- 右下角版权 -->
        <div class="homeCopyRight">
            <div class="text">
                <p>版权所有©普洱景迈山古茶林保护管理局</p>
                <p>技术支持：国信司南（北京）地理信息技术有限公司</p>
            </div>
        </div>
        <!-- 底部背景 -->
        <img src="@image/home/bg.png" alt="" class="bottomBgPart" />
    </div>
</template>
<script>
import HOME_INFO from "./home_config.js";
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
    components: {},
    name: "home",
    data() {
        return {
            HOME_INFO,
            systems: [],
        };
    },
    created() {},
    mounted() {
        this.formatRoute();
    },
    methods: {
        ...mapMutations({}),
        ...mapActions(["getSysToken", "getFuncTreeSecond"]),
        async formatRoute() {
            this.HOME_INFO.map((item) => {
                switch (item.ID) {
                    case "30": //遗产地监测管理
                        this.getMenus(item.ID).then((list) => {
                            if (list.length) {
                                this.dealRouteYcdjcgl(list, item);
                            }
                        });
                        break;
                    case "20": //移动数据采集管理
                        this.getMenus(item.ID).then((list) => {
                            if (list.length) {
                                this.dealRouteYdsjcj(list, item);
                            }
                        });
                        break;
                    case "10": //工作台面
                        this.getMenus(item.ID).then((list) => {
                            if (list.length) {
                                this.dealRouteGztm(list, item);
                            }
                        });
                        break;
                    case "50": //数据服务于管理
                        this.getMenus(item.ID).then((list) => {
                            if (list.length) {
                                this.dealRouteSjfwygl(list, item);
                            }
                        });
                        break;
                    case "40": //监测数据分析评估
                        this.getMenus(item.ID).then((list) => {
                            if (list.length) {
                                this.dealRouteJcsjfxpg(list, item);
                            }
                        });
                        break;
                    case "70": //运维管理
                        this.getMenus(item.ID).then((list) => {
                            if (list.length) {
                                this.dealRouteYwgl(list, item);
                            }
                        });
                        break;
                }
            });
            await this.getModulePower(this.HOME_INFO);
        },
        async getMenus(id) {
            let res = await this.getFuncTreeSecond({
                funcId: id,
            });
            return res;
        },
        // 遗产地监测管理 路由配置
        dealRouteYcdjcgl(list, obj) {
            switch (list[0].NAME) {
                case "预警预案":
                    obj.routeName = "warning_plan_grid";
                    break;
                case "预警对象设置":
                    obj.routeName = "warning_object_set_grid";
                    break;
                case "预警配置":
                    obj.routeName = "warning_config_grid";
                    break;
            }
        },
        // 移动数据采集管理  路由配置
        dealRouteYdsjcj(list, obj) {
            switch (list[0].NAME) {
                case "任务管理":
                    obj.routeName = "taskManage";
                    break;
                case "异常预警":
                    obj.routeName = "abnormalWarning";
                    break;
                case "任务执行":
                    obj.routeName = "taskExecution";
                    break;
                case "人员轨迹":
                    obj.routeName = "peopleLocus";
                    break;
                case "数据统计":
                    obj.routeName = "dataStatistics";
                    break;
                case "任务日志":
                    obj.routeName = "taskLog";
                    break;
                case "巡查报告":
                    obj.routeName = "patrolReport";
                    break;
            }
        },
        // 工作台面 路由配置
        dealRouteGztm(list, obj) {
            switch (list[0].NAME) {
                case "监测数据":
                    obj.routeName = "monitoringTable";
                    break;
                case "基础数据":
                    obj.routeName = "monitoringTable";
                    break;
                case "分析评估":
                    obj.routeName = "analysis-assess";
                    break;
                case "预警处置":
                    obj.routeName = "warning_dispose_grid";
                    break;
                case "专家分析":
                    obj.routeName = "expert_analysis";
                    break;
                case "专家信息":
                    obj.routeName = "expert_information_grid";
                    break;
            }
        },
        // 数据服务于管理 路由配置
        dealRouteSjfwygl(list, obj) {
            switch (list[0].NAME) {
                case "设备监控":
                    obj.routeName = "equipment_state";
                    break;
                case "数据管理":
                    obj.routeName =
                        "equipmentdata_waterquality_grid?state=collectdataEditable";
                    break;
                case "配置管理":
                    obj.routeName = "equipconfig_grid";
                    break;
                case "设备管理":
                    obj.routeName = "equipmanage_grid";
                    break;
                case "地图服务管理":
                    obj.routeName = "mapservice_manage_grid";
                    break;
            }
        },
        // 监测数据分析评估 路由配置
        dealRouteJcsjfxpg(list, obj) {
            switch (list[0].NAME) {
                case "分析评估":
                    obj.routeName = "assess_target_grid";
                    break;
                case "评估结果":
                    obj.routeName = "assess_result_list";
                    break;
            }
        },
        // 运维管理 路由配置
        dealRouteYwgl(list, obj) {
            switch (list[0].NAME) {
                case "用户管理":
                    obj.routeName = "user";
                    break;
                case "部门管理":
                    obj.routeName = "department";
                    break;
                case "角色管理":
                    obj.routeName = "role";
                    break;
                case "民居调查":
                    obj.routeName = "survey";
                    break;
                case "数据授权":
                    obj.routeName = "data_impower";
                    break;
            }
        },
        getStyle(item) {
            return {
                backgroundImage: `url(${require("@image/" +
                    item.bgPicture +
                    ".png")})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
            };
        },
        // 获得功能权限
        getModulePower(list) {
            let powerList =
                (this.modulePower && JSON.parse(this.modulePower)) ||
                (localStorage.modulePower &&
                    JSON.parse(localStorage.modulePower)) ||
                [];
            this.systems = [];
            list.map((item) => {
                let obj = powerList.find((val) => val.ID === item.ID);
                if (obj) {
                    item.state = true;
                } else {
                    item.state = false;
                }
            });
            this.systems = list;
            // powerList.forEach((v, ind) => {
            //     let item = list.find((val) => val.ID === v.ID);
            //     if (item) {
            //         item.state = v.state;
            //         this.systems.push(item);
            //     }
            // });
            if (this.systems.length == 1) {
                this.turnSystem(this.systems[0].routeName);
            }
        },
        async turnSystem(system) {
            if (!system.state) {
                this.common.showMsg("暂无权限", "warning");
                return;
            }
            let routeName = system.routeName;
            let systemName = system.name;
            if (system.toOtherSystem) {
                window.open(routeName);
            } else {
                let name = systemName.join("");
                let routerList = [];
                routerList.push({ path: routeName, name: name });
                localStorage.routerList = JSON.stringify(routerList);
                localStorage.currentSystem = name;
                localStorage.requestSystemId = system.ID || "";
                localStorage.requestSystemName = name || "";
                this.$router.push({
                    name: routeName,
                });
            }
        },
        toExit() {
            localStorage.clear();
            this.$router.push({
                path: "/login",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
#homePart {
    width: 100%;
    height: 100%;
    position: relative;

    .homeTitle {
        position: relative;
        .titleBg {
            width: calc(100% - 220px);
            height: 30%;
        }
        .title {
            width: 520px;
            height: 90px;
            position: absolute;
            top: 14%;
            left: 90px;
        }
        .title-desc {
            position: absolute;
            top: 40%;
            left: 90px;
            p {
                font-size: 20px;
                color: #e5e5e5;
                line-height: 36px;
            }
        }
    }
    .homeContainer {
        width: 100%;
        height: 180px;
        display: flex;
        justify-content: center;
        .menuItem {
            width: calc((100% - 220px) / 10);
            margin: 0 5px;
            text-align: center;
            cursor: pointer;
            .icon {
                width: 100px;
                height: 100px;
            }
            .name {
                margin-top: 10px;
                p {
                    text-align: center;
                    line-height: 30px;
                    font-size: 24px;
                    color: #4c6667;
                }
            }
        }
    }
    .homeCopyRight {
        width: 709px;
        height: 168px;
        height: 14%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        background: url("~@image/home/copyRightBg.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .text {
            padding-top: 2%;
            padding-right: 70px;
            p {
                font-size: 18px;
                text-align: right;
                color: #f2f2f2;
                line-height: 36px;
            }
        }
    }
    .bottomBgPart {
        width: 100%;
        height: 50%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
}
.actionPart {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
}

.noPower {
    filter: grayscale(100%);
}
</style>
