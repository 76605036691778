const modules = [
    {
        ID: '30',
        name: ["遗产监测", "业务管理"],
        routeName: "warning_plan",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/30"],
    },
    {
        ID: '20',
        name: ["移动数据", "采集管理"],
        routeName: "monitoringcloud",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/20"],
    },
    {
        ID: '10',
        name: ["工作台面"],
        routeName: "workdesk",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/10"],
    },
    {
        ID: '90',
        name: ["传统民居"],
        routeName: "data_get",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/90"],
    },
    {
        ID: '3',
        name: ["监测总览"],
        routeName: "big_screen",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/3"],
    },
    {
        ID: '50',
        name: ["数据服务", "与管理"],
        routeName: "equipment_state",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/50"],
    },
    // {
    //     ID: '92',
    //     name: ["游客量", "专项监测"],
    //     routeName: "home",
    //     bgColor: "rgb(88,67,206)",
    //     bgPicture: ["home/92"],
    // },
    {
        ID: '40',
        name: ["监测数据", "分析评估"],
        routeName: "analysis_assess",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/40"],
    },
    {
        ID: '37810e2d-1b2c-42bc-8062-3bd040ece5aa',
        name: ["档案系统"],
        routeName: `${window.jmsda}?token=${localStorage.accessToken}&refreshToken=${localStorage.refreshToken}`,
        toOtherSystem: true,
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/FileManage"],
    },
    {
        ID: '70',
        name: ["运维管理"],
        routeName: "usermanage",
        bgColor: "rgb(88,67,206)",
        bgPicture: ["home/70"],
    },
];
export default modules;