// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@image/home/copyRightBg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#homePart[data-v-aeafd63a]{width:100%;height:100%}#homePart[data-v-aeafd63a],#homePart .homeTitle[data-v-aeafd63a]{position:relative}#homePart .homeTitle .titleBg[data-v-aeafd63a]{width:calc(100% - 220px);height:30%}#homePart .homeTitle .title[data-v-aeafd63a]{width:520px;height:90px;position:absolute;top:14%;left:90px}#homePart .homeTitle .title-desc[data-v-aeafd63a]{position:absolute;top:40%;left:90px}#homePart .homeTitle .title-desc p[data-v-aeafd63a]{font-size:20px;color:#e5e5e5;line-height:36px}#homePart .homeContainer[data-v-aeafd63a]{width:100%;height:180px;display:flex;justify-content:center}#homePart .homeContainer .menuItem[data-v-aeafd63a]{width:calc(10% - 22px);margin:0 5px;text-align:center;cursor:pointer}#homePart .homeContainer .menuItem .icon[data-v-aeafd63a]{width:100px;height:100px}#homePart .homeContainer .menuItem .name[data-v-aeafd63a]{margin-top:10px}#homePart .homeContainer .menuItem .name p[data-v-aeafd63a]{text-align:center;line-height:30px;font-size:24px;color:#4c6667}#homePart .homeCopyRight[data-v-aeafd63a]{width:709px;height:168px;height:14%;position:absolute;bottom:0;right:0;z-index:1;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;display:flex;justify-content:flex-end;align-items:center}#homePart .homeCopyRight .text[data-v-aeafd63a]{padding-top:2%;padding-right:70px}#homePart .homeCopyRight .text p[data-v-aeafd63a]{font-size:18px;text-align:right;color:#f2f2f2;line-height:36px}#homePart .bottomBgPart[data-v-aeafd63a]{width:100%;height:50%;position:absolute;left:0;bottom:0;z-index:-1}.actionPart[data-v-aeafd63a]{position:absolute;right:10px;top:10px;z-index:10}.noPower[data-v-aeafd63a]{filter:grayscale(100%)}", ""]);
// Exports
module.exports = exports;
